import React from "react";
import { mainBlue, screen } from "../components/common/variables";
import styled from "styled-components";
import Layout from "../components/layout";
import SEO from "../components/seo";
import PageTitle from "../components/page-title";

const Wrapper = styled.div`
  padding-bottom: 110px;
  padding-top: 73px;
  min-height: 65vh;
  @media ${screen.xsmall} {
    padding-top: 87px;
    padding-bottom: 170px;
  }
  @media ${screen.xlarge} {
    padding-top: 91px;
    padding-bottom: 200px;
  }

  .content {
    width: 100%;
    padding-top: 35px;
    @media ${screen.xsmall} {
      padding-top: 40px;
      padding-left: 70px;
      padding-right: 70px;
    }
    @media ${screen.small} {
      padding-top: 82px;
      padding-left: 50px;
      padding-right: 50px;
    }
    @media ${screen.medium} {
      padding-top: 100px;
      padding-left: 160px;
      padding-right: 160px;
    }
    @media ${screen.xlarge} {
      padding-top: 140px;
      padding-left: 280px;
      padding-right: 280px;
    }

    &__title {
      margin-bottom: 15px;
      margin-left: 35px;
      @media ${screen.xsmall} {
        margin-bottom: 25px;
        margin-left: 0;
      }
    }

    &__description {
      color: ${mainBlue};
      max-width: 420px;
      font-size: 1.05rem;
      padding-left: 35px;
      padding-right: 35px;
      @media ${screen.xsmall} {
        padding-left: 0;
        padding-right: 0;
        font-size: 1.25rem;
      }
    }
  }
`;

const ThankYouPage = () => {
  return (
    <Layout>
      <SEO
        title={`Thank you | Enquiry received | Australian Thermic Lance Company`}
      />
      <Wrapper>
        <div className="content">
          <PageTitle className="content__title">Thank you</PageTitle>
          <p className="content__description">
            Your request has been received. Our team will get in touch with you
            shortly.
          </p>
        </div>
      </Wrapper>
    </Layout>
  );
};

export default ThankYouPage;
