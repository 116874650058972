// → page title for its style consistency

import React from "react";
import styled from "styled-components";
import { mainBlue, screen } from "../common/variables";

const Wrapper = styled.h1`
  color: ${mainBlue};
  font-size: 1.8rem;
  font-weight: 700;
  @media ${screen.xsmall} {
    font-size: 2.7rem;
  }
  @media ${screen.small} {
    font-size: 3.1rem;
  }
  @media ${screen.xlarge} {
    font-size: 3.3rem;
  }
`;

const PageTitle = ({ children, className }) => (
  <Wrapper className={className}>{children}</Wrapper>
);

export default PageTitle;
